<script setup>
import { computed, onMounted, ref, watch } from "vue";
import axios from "axios";
import { toast } from "vue3-toastify";

const currentAnnouncement = ref([]);
const show = ref(true);
const message = computed(() => currentAnnouncement.value?.content || "");
const title = computed(() => currentAnnouncement.value?.title || "");

const acknowledgeSave = () => {
    axios
        .post(`/announcements/${currentAnnouncement.value.id}/acknowledge`)
        .then(() => {
            // reload page
            getCurrentlyAnnouncements();
        })
        .catch((error) => {
            toast.error(
                error.response?.data?.message ||
                    "Something went wrong with acknowledgement.",
            );
        });
};

const getCurrentlyAnnouncements = () => {
    axios
        .get("/announcements/current")
        .then((response) => {
            currentAnnouncement.value = response.data;
        })
        .catch((error) => {
            toast.error(
                error.response?.data?.message ||
                    "Error fetching current announcement.",
            );
        });
};

watch(message, async () => {
    show.value = true;
});

onMounted(() => {
    getCurrentlyAnnouncements();
});
</script>

<template>
    <div
        v-if="currentAnnouncement.id"
        class="sticky -mt-12 sm:mt-0 sm:top-16 z-100"
    >
        <div
            v-if="show"
            class="static sm:top-0 z-100 min-w-full bg-white px-0 sm:px-4 pb-4 mb-8 border-b border-ha-300 shadow-lg"
        >
            <div class="flex pt-4">
                <div class="flex-shrink-0">
                    <svg
                        aria-hidden="true"
                        class="h-5 w-5 text-yellow-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                    >
                        <path
                            clip-rule="evenodd"
                            d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
                            fill-rule="evenodd"
                        />
                    </svg>
                </div>
                <div class="ml-3">
                    <h3 class="text-xl font-medium text-yellow-800">
                        {{ title }}
                    </h3>
                    <div class="mt-2 text-lg text-black" v-html="message"></div>
                    <template v-if="currentAnnouncement.media">
                        <div
                            v-for="media in currentAnnouncement.media"
                            :key="media.id"
                            class="mt-4"
                        >
                            <img
                                :src="media.temporary_link"
                                alt="image"
                                class="max-h-64 object-cover"
                            />
                        </div>
                    </template>
                    <div class="mt-8">
                        <div class="-mx-2 -my-1.5 flex">
                            <button
                                class="ml-3 rounded-md bg-yellow-200 border border-yellow-800 px-2 py-1.5 text-sm font-medium text-yellow-800 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-offset-2 focus:ring-offset-yellow-50"
                                type="button"
                                @click="acknowledgeSave"
                            >
                                Acknowledge
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
